<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <v-data-table
          :headers="headers"
          :items="data"
          item-key="minimum-salary"
          class="elevation-1"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.minimum_salary }}</td>
              <td>
                <toggle-button
                  :value="props.item.status == '' + 1 ? true : false"
                  color="#82C7EB"
                  :sync="true"
                  :labels="false"
                  @change="changeStatus(props.item)"
                />
                {{ props.item.status ? "Activated" : "Deactivated" }}
              </td>
              <td style="display: flex; flex-direction: row">
                <v-btn
                  class="
                    btn btn-sm btn-success
                    font-weight-bold
                    py-2
                    px-3 px-xxl-5
                    my-1
                  "
                  fab
                  small
                  @click="editButtonClick(props.item)"
                >
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  class="
                    btn btn-sm btn-danger
                    font-weight-bold
                    py-2
                    px-3 px-xxl-5
                    my-1
                  "
                  fab
                  small
                  @click="deleteButtonClick(props.item)"
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>

          <template v-slot:top>
            <v-btn
              class="
                btn btn-sm btn-success
                font-weight-bold
                py-2
                px-3 px-xxl-5
                my-1
              "
              fab
              small
              @click="createFormEnable"
            >
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
      <div class="col-md-4">
        <!-- create form start -->
        <v-form ref="form" v-if="createForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="minimumSalary"
            :rules="nameRules"
            label="Minimum salary"
            required
          ></v-text-field>
          <v-btn
            class="
              btn btn-sm btn-success
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            @click="createMinimumSalary"
          >
            Save
          </v-btn>
          <v-btn
            style="margin-left: 6%"
            class="
              btn btn-sm btn-danger
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            @click="cancel"
          >
            Cancel
          </v-btn>
        </v-form>
        <!-- create form end -->

        <!-- update form start -->
        <v-form ref="form" v-if="updateForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="minimumSalary"
            :rules="nameRules"
            label="Minimum salary"
            required
          ></v-text-field>
          <v-btn
            class="
              btn btn-sm btn-success
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            @click="updateMinimumSalary"
          >
            Update
          </v-btn>
          <v-btn
            style="margin-left: 6%"
            class="
              btn btn-sm btn-danger
              font-weight-bold
              py-2
              px-3 px-xxl-5
              my-1
            "
            @click="cancel"
          >
            Cancel
          </v-btn>
        </v-form>
        <!-- update form end -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "@/core/api";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      loading: true,
      success: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,
      createForm: false,
      updateForm: false,
      minimumSalary: "",
      minimum_salary_id: null,
      nameRules: [
        (v) => !!v || "Minimum salary is required",
        (v) =>
          (v && v.length <= 20) ||
          "Minimum salary must be less than 20 characters",
      ],
      headers: [
        { text: "Minimum Salary", value: "minimum_salary" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action" },
      ],
      data: [],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    cancel() {
      this.createForm = false;
      this.updateForm = false;
      this.minimumSalary = "";
    },
    createFormEnable() {
      this.minimumSalary = "";
      this.createForm = true;
      this.updateForm = false;
    },
    fetch() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/minimum-salary`)
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    createMinimumSalary() {
      if (this.minimumSalary == "") {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/minimum-salary`, {
          minimum_salary: this.minimumSalary,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.minimumSalary = "";
          Swal.fire({
            title: "",
            text: "Minimum salary added",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    updateMinimumSalary() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/minimum-salary/${this.minimum_salary_id}`, {
          minimum_salary: this.minimumSalary,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.minimumSalary = "";
          this.minimum_salary_id = "";
          Swal.fire({
            title: "",
            text: "Minimum salary updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },

    changeStatus(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/minimum-salary/${item.id}`, {
          minimum_salary: item.minimum_salary,
          status: !item.status,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.minimumSalary = "";
          this.minimum_salary_id = "";
          Swal.fire({
            title: "",
            text: "Status updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },

    editButtonClick(item) {
      this.createForm = false;
      this.updateForm = true;
      this.minimumSalary = item.minimum_salary;
      this.minimum_salary_id = item.id;
    },

    deleteButtonClick(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .delete(`${API_URL}/minimum-salary/${item.id}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "Minimum salary deleted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.loading = false;
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading = false;
        });
    },
  },
};
</script>
